





















































import {Component, Vue} from 'vue-property-decorator';
import {Message} from 'element-ui';
import {db} from '@/firebase';
import {collectionURLs} from '@/utils/config';

@Component
export default class Trash extends Vue {
  data: any = [];
  popoverDelete: any = {};
  popoverRestore: any = {};

  itemDelete(id: string, collection: string) {
    this.popoverDelete[id] = false;
    db.collection('Trash').doc(id + '(' + collection + ')').delete()
      .then(() => {
        Message({
          type: 'success',
          message: 'Question was permanently deleted',
          duration: 5 * 1000,
        });
        this.refresh();
      })
      .catch((error) => console.error(error));
  }

  async itemRestore(doc: any) {
    this.popoverRestore[doc.objectID] = false;
    db.collection(doc.collection).doc(doc.objectID).set(doc)
      .then(() => {
        this.$router.push(`${collectionURLs[doc.collection]}/edit/${doc.objectID}`);
      });
  }

  created() {
    this.refresh();
  }

  refresh() {
    db.collection('Trash').get()
      .then((querySnapshot) => {
        const data: any = [];
        querySnapshot.forEach((doc) => data.push(doc.data()));
        this.data = data;
      })
      .catch((error) => console.error('Error getting documents: ', error));
  }
}
