































import {Component, Vue} from 'vue-property-decorator';
import {db, storage} from '@/firebase';

@Component
export default class Exports extends Vue {
  items: any = [];

  download(location: string) {
    storage.ref().child(location).getDownloadURL().then((url) => {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    });
  }

  remove(item: any) {
    storage.ref().child(item.metadata.storage).delete()
      .then(() => {
        db.collection('Exports').doc(item.id).delete()
          .then(() => this.load())
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  created() {
    this.load();
  }

  load() {
    this.items = [];
    db.collection('Exports').get()
      .then((results: any) => {
        results.forEach((item: any) => {
          const dwn: any = item.data() || {};
          dwn.id = item.id;
          this.items.push(dwn);
        });
      });
  }

}
